import { DataAccess } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";

export class S25ChatbotService {
    public static async sendMessage(inputText: string): Promise<AiExpressSchedulingResponse> {
        let url = "/ai/request.json";
        let payload = { message: inputText };

        let [response, error] = await S25Util.Maybe(DataAccess.post<AiExpressSchedulingResponse>(url, payload));

        if (error) {
            return Promise.reject(error);
        }

        return response;
    }
}

// This shape is the required shape defined in AIRoomRequestController.java (25saas)
export type AiExpressSchedulingResponse = {
    chatResponse: string;
    eventName: string;
    startDate: string;
    endDate: string;
    maximumRoomCapacityNeeded: number;
};
